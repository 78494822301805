import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
// import ToggleColorMode from "./ToggleColorMode";
import App from "./App";
// import {apiClient, ApiClientContext} from './utils/ApiClient';

ReactDOM.render(
    // <ToggleColorMode>
        <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
                <App/>
            </SnackbarProvider>
        </BrowserRouter>
    // </ToggleColorMode>
    ,
    document.getElementById('root')
);


// import MainLayout from "./components/MainLayout";
import {Navigate, PartialRouteObject} from "react-router";
import DashboardLayout from "./components/DashboardLayout";
import Check, {checkLink} from "./pages/InvalidPassports/Check";
import {Login, loginLink} from "./pages/Auth/Login";
import {LoginResponse, loginResponseLink} from "./pages/Auth/LoginResponse";
import {LogoutResponse, logoutResponseLink} from "./pages/Auth/LogoutResponse";
import {Logout, logoutLink} from "./pages/Auth/Logout";
import Upload from "./pages/InvalidPassports/Upload";


const routes: PartialRouteObject[] = [
    {
        path: "/",
        element: <DashboardLayout/>,
        children: [
            {path: loginLink, element: <Login/>},
            {path: loginResponseLink, element: <LoginResponse/>},
            {path: logoutLink, element: <Logout/>},
            {path: logoutResponseLink, element: <LogoutResponse/>},
            {path: '/', element: <Navigate to='/invalid-passports/check'/>},
        ],

    },
    {
        path: "/invalid-passports",
        element: <DashboardLayout/>,
        children: [
            {path: 'check', element: <Check/>},
            // {path: 'upload', element: <Upload/>},
            {path: '/', element: <Navigate to='/invalid-passports/check'/>},
        ]
    }
    // {
    //     path: "main/",
    //     element: <MainLayout/>,
    //     children: [
    //         {path: '/', element: <Navigate to='/app/dashboard'/>}
    //
    //     ]
    // }
];

export default routes;
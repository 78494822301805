import {InvalidPassportsClient} from "../clients/ApiClient";
import {RuntimeConfig} from "./RuntimeConfig";
import {createContext, useContext} from "react";
import {useStore} from "../store";

export const httpAuth = {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
        // const token = store.getState().oidc.user?.access_token;
        // const headers = {
        //     'Authorization': `Bearer ${token}`,
        //     ...init?.headers as Headers
        // };
        return window.fetch(url, {...init});
    }
}

export const invalidPassportsClient = new InvalidPassportsClient(RuntimeConfig.ApiUrl, httpAuth);
export const InvalidPassportsClientContext = createContext(invalidPassportsClient);
export const useInvalidPassportsClient = () => {
    return useContext(InvalidPassportsClientContext);
};

export const useHandleApiClientException = (initMessage: string = "Сервер временно недоступен. Повторите попытку позднее") => {
    let {notificationStore} = useStore();

    return (message: string = initMessage) => {
        notificationStore.enqueueSnackbar(message, "error");
    }
}
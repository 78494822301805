import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useRoutes} from 'react-router-dom';
import routes from './routes';
import GlobalStyles from './components/GlobalStyles';
import Notifier from "./components/Notifier";

function App() {
    const routing = useRoutes(routes);

    return (
        <>
        // eslint-disable-next-line react/jsx-no-undef
            <Notifier/>
            <GlobalStyles/>
            {routing}
        </>
    );
}

export default App;

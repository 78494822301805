import React from 'react'
import {useStore} from "../../store";
import {Navigate} from "react-router-dom";

export const loginResponseLink = '/signin-oidc';

export const LoginResponse = () => {

    let {authStore} = useStore();

    authStore.completeLogin();

    return (
        <Navigate to="/"/>
    )
};
import {User, UserManager, WebStorageStateStore} from "oidc-client";
import {makeAutoObservable} from "mobx";
import {RuntimeConfig} from "../utils";

export default class AuthStore {
    manager: UserManager;
    user: User | null = null;

    constructor() {
        makeAutoObservable(this);

        let config =  {
            authority: RuntimeConfig.AuthUrl,
            client_id: "blacklists.web",
            redirect_uri: `${window.location.origin}/signin-oidc`,
            login: `${window.location.origin}/login`,
            post_logout_redirect_uri: `${window.location.origin}/signout-callback-oidc`,
            silent_redirect_uri: `${window.location.origin}/refresh-token`,
            automaticSilentRenew: true,
            response_type: "code",
            scope: "openid profile blacklists.api",
            acr_values: "Level3",
            ui_locales: "ru",
            loadUserInfo: true,
            revokeAccessTokenOnSignout: true,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        };
        this.manager = new UserManager(config);
    }

    login() {
        this.manager.signinRedirect()
            .catch((error) => this.handleError(error));
    }

    completeLogin() {
        if (this.user === null || this.user === undefined) {
            this.manager.signinRedirectCallback()
                .then(user => this.user = user)
                .catch((error) => this.handleError(error));
        }
    }

    logout() {
        this.manager.signoutRedirect()
            .catch((error) => this.handleError(error));
    }

    completeLogout() {
        this.manager.signoutRedirectCallback()
            .then(() => {this.manager.removeUser()})
            .then(() => {this.user = null;})
            .catch((error) => this.handleError(error));
    }

    get isAuthorized() {
        return (this.user !== null && this.user !== undefined);
    }



    handleError(error: any) {
        console.error("Problem with authentication endpoint: ", error);
    }
}
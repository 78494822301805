import {action, makeObservable, observable} from "mobx";

export type Variant = "info" | "warning" | "error";

interface INotificationItem {
    id: number,
    message: string,
    variant: Variant
}

class NotificationStore {
    notifications: INotificationItem[] = [];

    constructor() {
        makeObservable(this, {
            notifications: observable,
            enqueueSnackbar: action,
            removeSnackbar: action
        });
    }

    enqueueSnackbar(message: string, variant: Variant) {
        let note = {id: new Date().getTime() + Math.random(), message, variant};
        this.notifications.push(note);
    }

    removeSnackbar(id: number) {
        this.notifications = this.notifications.filter(notification => notification.id !== id);
    }
}

export default NotificationStore;
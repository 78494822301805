interface IRuntimeConfig {
    ApiUrl: string | undefined;
    AuthUrl: string | undefined;
}

export const RuntimeConfig: IRuntimeConfig = {
    //@ts-ignore
    ApiUrl: window._env_.API_URL,

    //@ts-ignore
    AuthUrl: window._env_.AUTH_URL
}
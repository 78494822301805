import {Box, experimentalStyled, Paper} from "@material-ui/core";
import {useState} from "react";
import DashboardSidebar from "./DashboardSidebar";
import {Outlet} from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";


const DashboardLayoutWrapper = experimentalStyled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
        paddingLeft: 256
    }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
});

const DashboardLayout = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <Paper elevation={0} sx={{height: "100vh"}}>
            <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)}></DashboardNavbar>
            <DashboardSidebar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            ></DashboardSidebar>
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet/>
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </Paper>
    );
};

export default DashboardLayout;
import React from 'react';
import {useStore} from "../../store";

export const logoutLink = '/signout-oidc'
export const Logout = () => {
    let {authStore} = useStore();

    authStore.logout();

    return (
        <div>Выход из сайта ...</div>
    );
};
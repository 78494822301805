import AuthStore from "./authStore";
import {createContext, useContext} from "react";
import NotificationStore from "./notificationStore";
// import {ThemeStore} from "./themeStore";

export interface IStore {
    authStore: AuthStore,
    notificationStore: NotificationStore,
    // themeStore: ThemeStore
}

export const store: IStore = {
    authStore: new AuthStore(),
    notificationStore: new NotificationStore(),
    // themeStore: new ThemeStore()
};


export const StoreContext = createContext(store);
export const useStore = () => {
    return useContext(StoreContext);
};
import React from 'react'
import {observer} from "mobx-react-lite";
import {useStore} from "../store";
import {autorun} from "mobx";
import {withSnackbar} from "notistack";

let Notifier = ({enqueueSnackbar}: any) => {
    const {notificationStore } = useStore();
    let displayed: number[] = [];

    let storeDisplayed = (id: number) => {
        displayed = [...displayed, id];
    };


    autorun(() => {
        const { notifications = [] } = notificationStore;

        notifications.forEach((notification) => {
            // Do nothing if snackbar is already displayed
            if (displayed.includes(notification.id)) return;
            // Display snackbar using notistack
            enqueueSnackbar(notification.message, { variant: notification.variant,  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                    transition: 'collapse'
                },});
            // Keep track of snackbars that we've displayed
            storeDisplayed(notification.id);
            // Dispatch action to remove snackbar from mobx store
            notificationStore.removeSnackbar(notification.id);
        });
    });
    return <></>

};

export default withSnackbar(observer(Notifier));

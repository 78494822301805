import React, {useEffect} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {Avatar, Box, Divider, Drawer, List, Typography} from "@material-ui/core";
import {
    Search as SearchIcon,
    Upload as UploadIcon
} from 'react-feather';

import NavItem from "./NavItem";
import {useStore} from "../store";

interface DashboardSidebar {
    onMobileClose: () => void,
    openMobile: boolean,
}

const items = [
    {
        href: '/invalid-passports/check',
        icon: SearchIcon,
        title: 'Недействительные паспорта'
    },
    // {
    //     href: '/invalid-passports/upload',
    //     icon: UploadIcon,
    //     title: 'Загрузка паспортов'
    // },
]

const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    jobTitle: 'Senior Developer',
    name: 'Katarina Smith'
};

const DashboardSidebar: React.FC<DashboardSidebar> = ({onMobileClose, openMobile}) => {

        let {authStore} = useStore();


        const location = useLocation();
        useEffect(() => {
            if (openMobile && onMobileClose != null) {
                onMobileClose();
            }
        }, [location.pathname]);


        const content = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                {authStore.isAuthorized && <>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2
                        }}
                    >

                        <Avatar
                            component={RouterLink}
                            src={user.avatar}
                            sx={{
                                cursor: 'pointer',
                                width: 64,
                                height: 64
                            }}
                            to="/app/account"
                        />
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {authStore.user?.profile.name}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {user.jobTitle}
                        </Typography>
                    </Box>
                    <Divider/>
                </>
                }
                <Box sx={{p: 1}}>
                    <List>
                        {items.map((item) => (
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        ))}
                    </List>
                </Box>
            </Box>
        )

        return (
            <>
                <Box sx={{display: {lg: 'none', xs: 'block'}}}>
                    <Drawer
                        anchor="left"
                        onClose={onMobileClose}
                        open={openMobile}
                        variant="temporary"
                        PaperProps={{
                            sx: {
                                width: 256
                            }
                        }}
                    >
                        {content}
                    </Drawer>
                </Box>
                <Box sx={{display: {md: 'block', xs: 'none'}}}>
                    <Drawer
                        anchor="left"
                        open
                        variant="persistent"
                        PaperProps={{
                            sx: {
                                width: 256,
                                top: 64,
                                height: 'calc(100% - 64px)'
                            }
                        }}
                    >
                        {content}
                    </Drawer>
                </Box>
            </>
        );
    }
;

export default DashboardSidebar;
import {AppBar  , Box, IconButton, Toolbar} from "@material-ui/core";
import React, {useState} from "react";
import {Link as RouterLink} from 'react-router-dom';
import Logo from "./Logo";
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import {useStore} from "../store";
import {loginLink} from "../pages/Auth/Login";
import {logoutLink} from "../pages/Auth/Logout";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import {useTheme} from "@material-ui/core/styles";

interface DashboardNavbarProps {
    onMobileNavOpen: () => void
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({onMobileNavOpen, ...rest}) => {
    const {authStore/*, themeStore*/} = useStore();
    // const theme = useTheme();
    return (
        <AppBar elevation={0}
                {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
                <Box sx={{flexGrow: 1}}/>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    {/*<IconButton sx={{ ml: 1 }} onClick={() => themeStore.toggleColorMode()} color="inherit">*/}
                    {/*    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}*/}
                    {/*</IconButton>*/}
                    {/*<IconButton color="inherit"*/}
                    {/*            component={RouterLink}*/}
                    {/*            to={authStore.isAuthorized ? logoutLink : loginLink}>*/}
                    {/*    <InputIcon/>*/}
                    {/*</IconButton>*/}
                </Box>
                <Box sx={{display: {xs: 'block', md: 'none'}}}>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>)
};


export default DashboardNavbar;
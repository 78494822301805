import React from 'react';
import {useStore} from "../../store";
import {Navigate} from "react-router-dom";

export const logoutResponseLink = '/signout-callback-oidc'

export const LogoutResponse = () => {
    let {authStore} = useStore();

    authStore.completeLogout();

    return (
        <Navigate to="/" />
    );
};
import React from 'react'
import {useStore} from "../../store";

export const loginLink: string = '/login';
export const Login = () => {

    let {authStore} = useStore();

    authStore.login();

    return (
        <span>Идет переадресация на страницу авторизации – пожалуйста подождите...</span>
    )

};

import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from "@material-ui/core";
import {invalidPassportsClient, useHandleApiClientException} from "../../utils";
import {SearchInvalidPassportsResponse} from "../../clients/ApiClient";

const initialSearchResult: SearchInvalidPassportsResponse = {
    found: false
};

export const checkLink: string = 'check';
const Check = () => {
    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);
    const [series, setSeries] = useState('');
    const [number, setNumber] = useState('');
    const [searchResult, setSearchResult] = useState(initialSearchResult);
    const handleApiClientException = useHandleApiClientException();

    const numberRef = useRef<HTMLInputElement>(null);
    const seriesRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (series.length === 4 && numberRef.current != null) {
            numberRef.current.focus();
        }
    }, [series, numberRef]);

    const handleSeriesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSeries(e.target.value);
    }

    const handleNumberChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNumber(e.target.value);
        if (e.target.value == '') {
            seriesRef?.current?.focus();
        }
    }

    const clear = () => {
        setNumber('');
        setSeries('');
        setLoading(false);
        setSearched(false);
    }

    const check = () => {
        setLoading(true);
        setSearched(false);
        invalidPassportsClient.search(series, number)
            .then(value => {
                setSearchResult(value);
                setSearched(true);
            })
            .catch(e => {
                handleApiClientException();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>Недействительные паспорта | Черные списки</title>
            </Helmet>

            <Card sx={{m: 4}}>
                <CardHeader
                    subheader="Проверка производится по серии и номеру паспорта РФ"
                    title="Проверка паспортов РФ" >
                </CardHeader>
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                inputProps={{
                                    maxLength: 4,
                                    required: true
                                }}
                                required
                                value={series}
                                onChange={handleSeriesChange}
                                id="series"
                                label="Серия"
                                variant="outlined"
                                size="small"
                                sx={{mr: 1, width: 83}}
                                inputRef={seriesRef}
                            />

                            <TextField
                                inputProps={{
                                    maxLength: 6,
                                    required: true
                                }}
                                required
                                value={number}
                                onChange={handleNumberChange}
                                id="number"
                                label="Номер"
                                variant="outlined"
                                sx={{mr: 1, width: 143}}
                                size="small"
                                inputRef={numberRef}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{mr: 1}}
                                onClick={check}
                            >
                                Проверить
                            </Button>

                            <Button
                                color="inherit"
                                variant="contained"
                                onClick={clear}
                            >
                                Очистить
                            </Button>
                        </Grid>
                    </Grid>
                    {searched && <Box sx={{mt: 2, maxWidth: 550}}>
                        {searchResult.found && <Alert severity="warning">Указанный паспорт недействительный</Alert>}
                        {!searchResult.found && <Alert severity="success">В реестре недействительных паспортов указанный паспорт не присутствет</Alert>}
                    </Box>
                    }
                </CardContent>

            </Card>
        </>
    );
};
export default Check;
